<template>
  <div class="video_placeholder text-center">
    <p class="emoji">{{emoji}}</p>    
    <p class="content mt-1">{{content}}</p>
  </div>
</template>

<script>

export default {
  name: 'videoPlaceholder',
  props: ['emoji', 'content']
}
</script>

<style>
  .video_placeholder {
    padding: 2.5rem 0.75rem;
    background-color: #E7E7E7;
  }

  .video_placeholder .emoji {
    font-size: 30px;
    line-height: 30px;
  }
</style>