<template>
  <div class="audio_player" v-bind:class="{'standalone' : standalone}">
    <div v-if="standalone === false" class="d-flex audio_player_header">
      <div class="d-flex flex-grow-1 align-items-center text-center">
        <i class="fa-solid fa-volume-low"></i>
        <span v-line-clamp="1" class="button_text">{{audioFileName ? audioFileName : $t('common.Audio_file')}}</span>
      </div>
    </div>
    <div>
      <vue-plyr v-if="audioSourceUrl" :options="plyrOptions">
        <audio controls crossorigin playsinline>
          <source :src="audioSourceUrl" type="audio/mpeg"/>
        </audio>
      </vue-plyr>
    </div>
  </div>
</template>

<script>

export default {
  name: 'audioPlayer',
  props: ['standalone', 'audioFileName', 'audioSourceUrl'],
  data() {
    return {
      plyrOptions: {
        controls: ['play', 'progress', 'current-time', 'mute', 'volume']
      }
    }
  }  
}
</script>